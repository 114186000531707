<template>
  <v-card class="latest-news-card" tile flat>
    <v-img
        height="225"
        :src="getImgUrl('img/'+ item.img)"
    ></v-img>

    <div class="pa-6">
      <div class="latest-news-card__date">{{ item.created | date }}</div>
      <div style="height:124px;max-height:124px">
        <div class="mt-3 text-m-h3 text-lg-h4 ellipse two-lines">{{ item.title }}</div>
        <div class="mt-2 text-body-3 opacity-semi ellipse two-lines">{{ item.description }}</div>
      </div>

      <div class="mt-4">
        <router-link :to="`/blog/${item.to}`" class="link--no-decoration secondary--text text-body-3 font-weight-bold">Read more</router-link>
      </div>
    </div>

  </v-card>
</template>
<script>
import getImgUrl from '@/mixins/getImgUrl';

export default {
  name: 'NewsCard',
  props: {
    item: {},
  },
  mixins: [getImgUrl],
};
</script>
<style lang="scss" scoped>

.ellipse {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.latest-news-card {
  &__date {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-variant: small-caps;
    font-family: $heading-font-family2;
    text-transform: uppercase;

    @media #{map-get($display-breakpoints, 'lg-and-up')}{
      font-size: 16px;
      line-height: 19px;
    }
  }
}

</style>
